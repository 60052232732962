import React, { useEffect } from 'react';
import { Column, Container, Row } from '@nimles/react-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrder } from '@nimles/react-redux';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { RootState } from '../../redux/types';
import { OrderModel } from '@nimles/models';
import queryString from 'query-string';
import Layout from '../../layouts/Layout';
import { graphql } from 'gatsby';

export default function CheckoutConfirmation({
  location,
}: {
  location: Location;
}) {
  const dispatch = useDispatch();
  const orders = useSelector<RootState, OrderModel[]>(
    ({ orders }) => orders.values
  );

  const { t } = useTranslation();

  const { orderId }: any = location
    ? queryString.parse(location.search)
    : { orderId: null };

  useEffect(() => {
    if (orderId) {
      dispatch(loadOrder(orderId));
    }
  }, [orderId]);

  const order = orderId && orders.find((o) => o.id === orderId);

  return (
    <Layout header location={location} isCheckout>
      {order && (
        <Container padding="200px 0 0">
          <Row justify="center" wrap="wrap">
            <Column xs={100} lg={50}>
              <h1>Tack för din order</h1>
              <Link to="/">Gör en ny beställning</Link>
            </Column>
          </Row>
        </Container>
      )}
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
